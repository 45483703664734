import { render } from "react-dom";
import { App } from "./index";

const head = document.getElementsByTagName("head")[0];
const link = document.createElement("link");
link.rel = "stylesheet";
link.type = "text/css";
link.href = require("url:./style.scss");
head.appendChild(link);

const elements = document.querySelectorAll("[data-widget]");

// @ts-ignore
for (const element of elements) {
  const { widgetType } = element.dataset;

  render(<App widgetType={widgetType} />, element);
}
