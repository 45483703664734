import React, { Dispatch, SetStateAction } from "react";
import { useCombobox } from "downshift";
import { SearchIcon } from "@heroicons/react/solid";
import { classNames } from "../helper";

type AutocompleteProps = {
  onSelected: Dispatch<SetStateAction<SearchHit>>;
  extraClass?: string;
  dropDownClass?: string;
};

type SearchResponse = {
  facet_counts: Array<any>;
  found: number;
  hits: Array<{ document: SearchHit }>;
  out_of: number;
  page: number;
  request_params: any;
  search_time_ms: number;
};

export type SearchHit = {
  id: string;
  full_name: string;
  slug: string;
  manufacturer_slug: number;
  top_price: number;
};

async function searchRequest(searchTerm: string | undefined) {
  const response = await fetch(
    process.env.SEARCH_URL +
      "collections/products/documents/search?" +
      new URLSearchParams({
        q: searchTerm || "",
        query_by: "full_name",
        include_fields: "full_name,id,slug,manufacturer_slug,top_price",
        highlight_fields: "",
      }),
    {
      headers: {
        "X-TYPESENSE-API-KEY": process.env.SEARCH_TOKEN || "",
      },
    }
  );
  return (await response.json()) as SearchResponse;
}

export const Autocomplete: React.FC<AutocompleteProps> = ({
  onSelected,
  extraClass = "sm:max-w-xs",
    dropDownClass= "lg:max-w-7xl"
}) => {
  const [hits, setHits] = React.useState<Array<SearchHit>>([]);

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    items: hits,
    itemToString: (item) => {
      return item?.full_name || "";
    },
    onInputValueChange: async ({ inputValue }) => {
      const response = await searchRequest(inputValue);
      setHits(response.hits.map((value) => value.document));
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (!selectedItem) {
        return;
      }
      onSelected(selectedItem);
    },
  });

  return (
    <div className={`w-full ${extraClass} mx-auto`}>
      <div
        className="sm:flex sm:flex-1 sm:items-center sm:justify-center"
        {...getComboboxProps()}
      >
        <div className={`w-full`}>
          <label htmlFor="email" className="sr-only" {...getLabelProps()}>
            Search:
          </label>
          <div className="relative">
            <input
              type="text"
              {...getInputProps()}
              className="p-4 bg-white text-black shadow-sm focus:ring-primary focus:border-primary block w-full rounded-sm"
              placeholder="Search our devices..."
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <SearchIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
          </div>
        </div>
      </div>
      <ul
        className={classNames(
          !isOpen
            ? "sr-only"
            : `absolute z-30 mt-1 w-4/5 max-w-sm bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm sm:items-center sm:justify-center mx-auto inset-x-0 ${dropDownClass}`
        )}
        {...getMenuProps()}
      >
        {isOpen && hits.length ? (
          hits.map((item, index) => {
            return (
              <li
                className={classNames(
                  highlightedIndex === index
                    ? "text-recycling-primary"
                    : "text-black",
                  "cursor-default select-none relative py-2 pl-3 pr-9"
                )}
                key={`${item.id}${index}`}
                {...getItemProps({ item, index })}
              >
                <span
                  className={classNames(
                    highlightedIndex === index
                      ? "font-semibold"
                      : "font-normal",
                    "block truncate"
                  )}
                >
                  {item.full_name}
                </span>
              </li>
            );
          })
        ) : (
          <li className="text-gray-900 select-none relative py-2 pl-3 pr-9">
            <span className="font-normal block truncate">
              No products found
            </span>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Autocomplete;
