import Autocomplete, { SearchHit } from "../components/Autocomplete";
import { useState } from "react";

const ProductSelection = () => {
  const [selected, setSelected] = useState<SearchHit>(null);
  if (selected === null) {
    return (
      <div className="flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0 items-center justify-center py-2">
        <div className="w-full">
          <Autocomplete
            onSelected={setSelected}
            extraClass="mx-auto sm:max-w-lg lg:max-w-2xl"
            dropDownClass="lg:max-w-2xl"
          />
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0 items-center justify-center py-2 sm:max-w-lg mx-auto lg:max-w-2xl">
          <Autocomplete onSelected={setSelected} extraClass="mx-auto sm:max-w-lg lg:max-w-2xl w-full" dropDownClass="lg:max-w-2xl" />
          <div className="p-4 bg-white text-black font-medium shadow-sm focus:ring-recycling-primary focus:border-recycling-primary block w-full rounded-sm max-w-[200px]">
              <h2 className="float-left">
                  You could get: <span>£{selected.top_price}</span>
              </h2>
          </div>
      </div>
      <a
        href={`https://sell.handtec.co.uk/sell/${selected.manufacturer_slug}/${selected.slug}?mode=top`}
        className="inline-flex items-center justify-center rounded-sm border border-transparent px-4 py-2 mt-4 text-base font-medium
          shadow-sm
          sm:text-lg bg-black hover:bg-gray-800 focus:ring-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100"
      >
        Sell Us Your Phone
      </a>
    </>
  );
};

export const Product = () => {
  return (
    <div className="bg-orange-600 text-white p-6 text-center space-y-4 w-full">
      <h3 className="text-4xl font-bold text-white">Sell your old phone - what’s it worth?</h3>
      <ProductSelection />
    </div>
  );
};
