import Autocomplete from "../components/Autocomplete";

export const Home = () => {
  const navigate = (selected) => {
    window.location.href = `https://sell.handtec.co.uk/sell/${selected.manufacturer_slug}/${selected.slug}?mode=top`;
  };

  return (
    <div className="text-white p-32 text-center bg-orange-600 w-screen">
      <h3 className="text-6xl font-bold text-white">Trade In Your Existing Phone</h3>
      <div className="w-full my-4">
        <Autocomplete onSelected={navigate} extraClass="mx-auto sm:max-w-lg lg:max-w-7xl" />
      </div>
    </div>
  );
};
